.about-content {
  max-width: 1200px;
  margin: 0 auto;
}

.about-section {
  margin-bottom: 2rem;
  padding: 1rem 2rem;
  background: var(--background-color);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.about-section h2 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.about-link-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.about-link {
  display: inline-block;
  font-size: 1rem;
  color: var(--secondary-color);
  margin-top: 1rem;
  text-decoration: none;
  transition: all 0.3s;
}

.about-link:hover {
  color: var(--primary-color);
  transform: scalex(1.2);
}

@media (max-width: 768px) {
  .about-section {
    padding: 1rem;
  }
}
