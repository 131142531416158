.page-section {
  padding: 50px 20px;
  background: #f9f9f9;
  text-align: justify;
  width: 100%;
}

.about-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #025b6c;
}

.about-description {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
}
