.contact-content {
  max-width: 1200px;
  margin: 0 auto;
}

.contact-section {
  margin-bottom: 3rem;
  padding: 1.5rem 2rem;
  background: var(--background-color);
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact-section .getin-touch {
  padding-top: 20px;
}
.contact-section .message-us {
  flex: 1;
  max-width: 600px;
}

.contact-section h2,
.contact-section h3 {
  color: var(--primary-color);
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.contact-section h2.section-heading {
  font-size: 2.4rem;
  text-align: center;
}

.contact-section p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

.contact-section.contact-message {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.section-subheading {
  text-align: center;
  font-size: 1.3rem;
  color: var(--secondary-color);
}

.contact-info {
  list-style-type: none;
  padding: 0;
  text-align: center;
  font-size: 1.2rem;
}

.contact-info li {
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--primary-color);
}

.contact-info li.email,
.contact-info li.email a {
  /* color: var(--email-color); */
}

.contact-info li.call,
.contact-info li.call a {
  /* color: var(--call-color); */
}

.contact-info li.whatsapp,
.contact-info li.whatsapp a {
  /* color: var(--whastsapp-color); */
}

.contact-info li a {
  color: var(--secondary-color);
  text-decoration: none;
  margin-left: 10px;
}

.contact-info li a:hover {
  color: var(--primary-color);
}

.contact-info .fa {
  margin-right: 10px;
  font-size: 1.5rem;
}

/* ----------------- */
.message-us .form-container {
  width: 100%;
  padding: 0;
  border-radius: 8px;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
}

.message-us .form-container form {
  padding: 20px;
  box-shadow: none;
  background-color: transparent;
  border-radius: 10px;
}

.form-group {
  margin-bottom: 1.5rem;
}

.message-us .form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--primary-color);
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 6px;
  outline: none;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

.form-group textarea {
  resize: vertical;
  min-height: 120px;
}

.feedback {
  font-size: 16px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
}

.success {
  color: #01f83b; /* Dark green */
}

.error {
  color: #d80b20; /* Dark red */
  font-size: 10px;
}

button[type="submit"] {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

button[type="submit"]:hover {
  background-color: var(--secondary-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

button[type="submit"]:active {
  background-color: #0056b3;
  box-shadow: none;
}

@media (max-width: 768px) {
  .form-container {
    padding: 1.5rem;
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.9rem;
    padding: 0.6rem;
  }

  button[type="submit"] {
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
  }
}

/* ---------------- */

.contact-link {
  font-size: 2rem;
  display: inline-block;
  margin-top: 2rem;
  color: var(--secondary-color);
  transition: color 0.3s;
}

.contact-link:hover {
  color: var(--primary-color);
}

.link-text {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 1rem;
  color: #666;
}

@media (max-width: 768px) {
  .contact-section {
    padding: 1.5rem;
  }

  .contact-info li {
    font-size: 1rem;
  }
}
