.notification-subscription-page {
  padding: 2rem;
  background: #f8f9fa;
  color: #2c3e50;
}

h1 {
  font-size: 2.5rem;
  color: #34495e;
  margin-bottom: 0.5rem;
}

p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #7f8c8d;
}

.subscriptions-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.subscriptions-table th,
.subscriptions-table td {
  border: 1px solid #ddd;
  padding: 1rem;
  text-align: left;
}

.subscriptions-table th {
  background: #2c3e50;
  color: white;
  font-weight: bold;
}

.subscriptions-table tr:nth-child(even) {
  background: #f2f2f2;
}

.expand-button {
  background: none;
  border: none;
  font-size: 1.2rem;
  color: #16a085;
  cursor: pointer;
}

.expand-button:hover {
  color: #1abc9c;
}

.expanded-row td {
  background: #ecf0f1;
  border-top: none;
}

.subscription-details {
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.subscription-details ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.subscription-details li {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}
