/* UsersPageList.css */
.users-page-list {
  padding: 20px;
  background-color: #f4f4f4;
}

.users-page-list h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
}

.users-table th,
.users-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.users-table th {
  background-color: #333;
  color: white;
}

.users-table button {
  padding: 5px 10px;
  font-size: 14px;
  margin-right: 5px;
  cursor: pointer;
}

.users-table button:hover {
  background-color: #ddd;
}
