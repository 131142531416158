.footer {
  box-shadow: var(--footer-shadow);
  color: white;
  padding: 0;
  position: relative;
  overflow: hidden;
  min-height: 50px;
}

.footer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* footer-row Styles common */
.footer-row {
  width: 100%;
  height: auto;
  display: flex; /* Create columns */
  padding: 10px;
}

.footer-footer.footer-row {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  min-height: 100px;
  color: aqua;
}

/* footer-column Styles common */
.footer-column {
  flex: 1; /* Allow columns to grow equally */
  display: flex; /* Flexbox for inner content */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  color: black; /* Change text color for visibility */
}

.footer-row:not(:last-child) .footer-column {
  color: azure;
  flex-basis: 30%;
}

.footer-bottom.footer-row .footer-column {
  color: var(--footer-copyright-color);
  font-size: 12px;
}

.footer-column:not(:last-child) {
  border-right: var(--border-seperator);
}

.footer-column a {
  display: flex;
  align-items: center;
  gap: 0px 10px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

.footer-column.contact a {
  border-radius: 20px;
  padding: 10px 20px;
  box-shadow: var(--shadow-call-button);
}

.footer-column a:hover {
  transform: scale(1.05);
  color: var(--secondary-color);
}

.footer-title {
  font-size: 20px;
  letter-spacing: 1px;
  color: var(--footer-title-color);
}

/* ///////////////////// */

.footer-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  row-gap: 0px;
  background-color: rgba(250, 250, 250, 0.9);
  padding: 10px 35px;
  border-radius: 5px;
  box-shadow: var(--shadow-dark);
}

.footer-logo-container .top-text {
  font-size: 10px;
  color: rgb(141, 141, 148);
  position: absolute;
  top: 10px;
  right: 35px;
  letter-spacing: 3px;
  font-weight: bold;
}

.footer-logo-container .footer-logo {
  width: 100px;
  margin: 0;
}

.footer-logo-container .tagline {
  font-size: 10px;
  letter-spacing: 3px;
  font-weight: 300;
  color: var(--secondary-color);
  align-self: center;
  margin-left: 10px;
  position: relative;
  top: -5px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.contact {
  flex-direction: column;
  gap: 0;
  position: relative;
  justify-content: center;
  align-items: center;
}

.social {
  justify-content: space-evenly;
  gap: 0px 10px;
}

.online {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.quicklinks {
  flex-direction: column;
  align-items: center;
}

/* Subscription Form Styles */
.subscription {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  padding: 0px 20px;
}

.subscribe-form {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 400px;
  background-color: var(--footer-bg-color);
  border-radius: 30px;
  box-shadow: var(--shadow-button);
  position: relative;
}

.subscribe-input {
  flex: 1;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 30px 0 0 30px;
  font-size: 15px;
  outline: none;
  box-sizing: border-box;
}

.subscribe-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 0 30px 30px 0;
  cursor: pointer;
}

.submit-icon.spinning {
  animation: spin 1s linear infinite; /* Spinning icon */
}

/* Subscription Form Messages */
.message {
  position: absolute;
  font-size: 14px;
  width: inherit;
  text-align: center;
  height: 20px;
  color: transparent;
  left: 0;
  top: -25px;
  border-radius: 25px;
}

.message.error {
  color: red;
}

.message.success {
  color: #06ff06;
}
.message.warning {
  color: #ff9b06;
}
/* Spinning animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .footer-header {
    border-bottom: var(--border-seperator);
  }

  .footer-footer {
    flex-wrap: wrap;
    gap: 15px 0;
  }

  .footer-footer .footer-column:not(:first-child) {
    border: none;
  }

  .footer-footer .footer-column.online {
    flex-basis: 45%;
  }

  .footer-footer .footer-column.quicklinks {
    flex-basis: 45%;
  }

  .footer-footer .footer-column.subscription {
    border: none;
    flex-basis: 100%;
    border-top: var(--border-seperator);
    padding: 15px;
  }
}

@media (max-width: 575px) {
  .footer-header {
    flex-wrap: wrap;
    border-bottom: var(--border-seperator);
    gap: 15px 0px;
  }
  .footer-header .footer-column.contact {
    order: 1;
    flex-basis: 100%;
  }
}

@media (max-width: 400px) {
  .footer-header {
    border: none;
    flex-wrap: wrap;
    gap: 0px;
    padding: 15px;
  }
  .footer-header .footer-column {
    border: none;
    padding: 15px;
    order: 1;
    flex-basis: 100%;
    border-bottom: var(--border-seperator);
  }

  .footer-row.footer-footer {
    border: none;
    flex-wrap: wrap;
    gap: 0px;
    flex-direction: column;
    padding: 0 15px;
  }

  .footer-row.footer-footer .footer-column {
    border: none;
    padding: 15px 15px;
    border-bottom: var(--border-seperator);
  }
}
