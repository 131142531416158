.header-dashboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #34495e;
  color: white;
  padding: 15px 20px;
  position: sticky;
  top: 0;
  z-index: 100;
  height: var(--h-header-dashboard);
  box-shadow: var(--shadow-dark);
}

.sidebar-toggle-btn {
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
}

.sidebar-toggle-btn.collapsed {
  color: var(--secondary-color);
}

.header-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.header-actions {
  display: flex;
  gap: 10px;
}

.header-btn {
  background-color: #1abc9c;
  border: none;
  color: white;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
}

.header-btn:hover {
  background-color: #16a085;
}

.rounded-link-button {
  padding: 5px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: var(--primary-color);
  text-decoration: none;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.2s;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded-link-button:hover {
  background-color: var(--secondary-color); /* Replace with hover color */
  transform: translateY(-3px);
}
