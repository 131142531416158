.notification-messages-page {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: white; /* Plain white background */
  border-radius: 8px;
  color: black;
}

.notification-messages-page h1 {
  text-align: center;
  margin-bottom: 20px;
}

.messages-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.messages-table th,
.messages-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd; /* Light grey border */
}

.messages-table th {
  background-color: #f4f4f4; /* Light grey background for header */
  color: black;
}

.messages-table td {
  background-color: #fff;
}

.messages-table tr.read td {
  background-color: #e0e0e0; /* Slightly grey background for read messages */
}

.messages-table tr.unread td {
  background-color: #fff; /* Keep the background white for unread messages */
  font-weight: bold; /* Highlight unread messages */
}

.mark-read-button {
  padding: 8px 15px;
  background-color: #4caf50; /* Green for success */
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.mark-read-button:hover {
  background-color: #45a049;
}

.mark-read-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
