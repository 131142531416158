.dashboard-home {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #2c3e50;
  background: linear-gradient(135deg, #1abc9c, #16a085);
  min-height: calc(
    100vh - var(--h-header-dashboard)
  ); /* Adjust height minus header */
}

.welcome-section {
  text-align: center;
  margin-bottom: 3rem;
}

.welcome-title {
  font-size: 3rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 1rem;
}

.welcome-description {
  font-size: 1.2rem;
  font-weight: 400;
  color: #ecf0f1;
  max-width: 700px;
  margin: 0 auto;
}

.stats-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.stat-box {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  text-align: center;
  width: 250px;
}

.stat-box h3 {
  font-size: 1.5rem;
  color: #34495e;
  margin-bottom: 0.5rem;
}

.stat-box p {
  font-size: 1.2rem;
  font-weight: 600;
  color: #1abc9c;
}
