.settings-page {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #2c3e50;
  border-radius: 8px;
  color: white;
}

.settings-page h1 {
  text-align: center;
  margin-bottom: 20px;
}

.settings-section {
  margin-bottom: 20px;
}

.settings-section h2 {
  margin-bottom: 10px;
  font-size: 1.2rem;
  border-bottom: 1px solid #34495e;
  padding-bottom: 5px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.9rem;
}

/* .form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="password"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #34495e;
  border-radius: 4px;
  background-color: #34495e;
  color: white;
} */

.form-group input[type="checkbox"] {
  margin-right: 10px;
}

.save-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #1abc9c;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  color: white;
  cursor: pointer;
}

.save-button:hover {
  background-color: #16a085;
}
