.services-section.page {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 20px 35px;
}

.service-detail {
  display: flex;
  align-items: center;
  gap: 25px;
  padding: 20px;
  box-shadow: var(--shadow-dark);
  border-radius: 10px;
  justify-content: space-evenly;
}

.service-detail.align-left {
  flex-direction: row;
}

.service-detail.align-right {
  flex-direction: row-reverse;
}

.service-image {
  max-width: 300px;
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-image:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.service-content {
  max-width: 60%;
  display: flex;
  flex-direction: column;
}

.service-content.align-right {
  align-items: flex-end;
  text-align: right;
}

.service-title {
  font-size: 1.8rem;
  color: var(--primary-color);
  margin-bottom: 15px;
}

.service-description {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #444;
  margin-bottom: 20px;
}

.service-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.service-list-item {
  position: relative;
  padding-right: 20px;
  font-size: 1rem;
  line-height: 1.6;
  display: flex;
  align-items: center;
  color: #6a6c6e;
}
.align-left .service-list-item {
  justify-content: flex-end;
}

.service-list-item:before {
  content: "•";
  position: absolute;
  right: 0;
  color: var(--secondary-color);
  font-size: 1.5rem;
}

.service-content.align-right .service-list-item {
  padding-right: 0;
  padding-left: 20px;
}

.service-content.align-right .service-list-item:before {
  left: 0;
  right: unset;
}

.align-left .service-title,
.align-right .service-list {
  text-align: left;
}

.align-right .service-title,
.align-left .service-list {
  text-align: right;
}

@media (max-width: 768px) {
  .service-detail.align-left,
  .service-detail.align-right {
    flex-direction: column;
    gap: 20px;
  }

  .service-content {
    max-width: 100%;
    align-items: flex-start;
    text-align: left;
  }

  .service-content.align-right {
    align-items: flex-start;
    text-align: left;
  }

  .service-image {
    max-width: 100%;
  }
}
