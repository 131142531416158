.pages-hero {
  display: flex;
  align-items: end;
  justify-content: flex-start;
  width: 100%;
  background-color: var(--primary-light-color);
  box-shadow: var(--shadow-light);
  border-radius: 8px;
  margin-bottom: 20px;
}
.page-hero-contents {
  display: flex;
  align-items: center;
  /* box-shadow: -5px 1px 3px #006fb96b; */
}

.hero-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 0 40px 40px; /* Triangle dimensions */
  border-color: transparent transparent transparent #006fb96b;
}

.pages-hero-content {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 0px 20px;
}

.pages-hero-title {
  font-size: 2rem;
  font-weight: 700;
  color: var(--secondary-color);
  margin: 0;
  text-transform: capitalize;
}

.pages-hero-subtitle {
  font-size: 1rem;
  font-weight: 400;
  color: #868484;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .pages-hero {
    max-width: 100%; /* Full width on smaller screens */
    padding: 15px;
  }

  .pages-hero-title {
    font-size: 1.5rem;
  }

  .pages-hero-subtitle {
    font-size: 0.9rem;
  }

  .hero-triangle {
    border-width: 30px 0 30px 30px; /* Smaller triangle for smaller screens */
  }
}
