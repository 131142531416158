.services-section {
  padding: 50px 20px;
  /* background: #f9f9f9; */
  text-align: center;
  width: 100%;
}

.services-title {
  font-size: 2rem;
  margin-bottom: 40px;
  color: #025b6c;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.service-card {
  width: 300px;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.service-icon {
  font-size: 2rem;
  color: var(--secondary-color);
  margin-bottom: 15px;
}

.service-title {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.service-description {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
}
